$form-bg: #1f72c6;

.App {
  text-align: center;
}
.user-app {
  background-color: #efefef;
  padding: 1.2em;
  .new-user{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2em;
    input{
      width: 80% !important;
    }
  }
}

.list-group{
  .list-group-item{
    display: flex;  
    justify-content: space-between;
    align-items: center;
    border: none;
    border-bottom: 1px solid #d3d3d3;
    &:last-child{
      border-bottom: none;
    }
    .user-text{
      width: 80%;
      text-align: left;
      &.completed{
        text-decoration: line-through;
      }
    }
    .user-actions{
      width: 20%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 0;
  box-shadow: inset 2px 0 0 rgba(8, 63, 227, 0.1);
  width: 15em;
  transition: 0.3s; 
  white-space: nowrap;
  background-color: #f8f9fa;
  .btn{
    position: absolute;
    top: 1em;
    right: 90%;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    margin-bottom: 1em;
    border: none;
    transition: 0.2s;
    &:hover{
      background-color: #9900a7;
      width: 4em;
      height: 4em;
      top: 0.5em;
      right: 85%;
    }
  }
  .sidebar-body{
    display: block;
    padding: 1em;
    text-wrap: wrap;
  }
}

.Logo{
  position: absolute;
  top: 1em;
  left: 1em;
  width: 3em;
  border: none;
}

.form-group{
  display: flex;
  flex-direction: column;
  .form-line-1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 1em;
  }
  .form-line-2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0.5em;
  }
  .form-line-3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 1em;
  }
}

.add-user{

  .form-div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0px;
    overflow: hidden;
    overflow-y: scroll;
    transition: height 0.2s;
    
  }

  .form-toggle-button{
    position: sticky;
    border-radius: 50%;
    width: 3em;
    height: 3em;
    margin-bottom: 1em;
    border: none;
    transition: 0.2s;
    &:hover{
      background-color: #9900a7;
      width: 4em;
      height: 4em;
    }
  }
}

.form-control{
  width: 100%;
  margin-bottom: 0.5em;
}

//.list-group-item{
//  &.active:hover{
//
//  }
//  &active:hover{
//    background-color: #d3d3d3;
//  }
//}